<script>
  import { afterUpdate } from "svelte";
  import { _, locale } from "svelte-i18n";
  import Carousel from "./Carousel.svelte";
  import { defaultLocale } from "../i18n.js";

  export let id;
  export let name;
  export let region;
  export let country;
  export let arrivalDate;
  export let photoCount;

  const typeToIconMap = {
    "old-town": "storefront",
    city: "location_city",
    mountain: "landscape",
    nature: "nature"
  };

  let nameEl;
  let localeName;

  afterUpdate(() => {
    const lang = $locale.substr(0, 2);
    localeName = name[lang] || name[defaultLocale];
  });
</script>

<style type="text/scss">.place-card {
  position: relative;
  box-sizing: border-box;
  max-width: 900px;
  background-color: #ffefd6;
  width: calc(100% - 28px);
  margin: 5vh auto 0 auto;
  padding: 0 1vw 1vw 1vw;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); }
  .place-card .card-header {
    display: flex;
    height: 10vh;
    justify-content: space-between;
    padding: 1vh 0.5vw 0.3vh 1vw; }
    .place-card .card-header .name {
      font-size: 5vh;
      line-height: 9.5vh; }
    .place-card .card-header .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 2.2vh;
      line-height: 4.4vh;
      text-align: right; }
  .place-card .card-body {
    max-height: 60vh; }

@media (orientation: landscape) {
  .place-card {
    width: 65vw;
    max-height: 90vh;
    margin: 5vh 0 5vh 5vh; }
    .place-card .card-body {
      max-height: 75vh; } }</style>

<div class="place-card">
  <div class="card-header">
    <div bind:this={nameEl} class="name">{localeName}</div>
    <div class="details">
      <div class="arrival-date">
        {arrivalDate.toLocaleDateString($locale, {})}
      </div>
      <div class="region">
        {region ? $_('regions.' + region) + $_('regions.suffix') : ''}{#each country as c, i}{(i > 0 ? $_('countries.separator') : '') + $_('countries.' + c)}{/each}
      </div>
    </div>
  </div>
  <div class="card-body">
    {#if photoCount > 0}
      <Carousel
        {photoCount}
        folderName={id}
        altText={localeName}
        on:photoClicked />
    {/if}
  </div>
</div>

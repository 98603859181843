<script>
  import { locale, locales } from "svelte-i18n";

  const next = function() {
    let index = $locales.indexOf($locale.substr(0, 2));

    if (++index === $locales.length) {
      index = 0;
    }

    locale.set($locales[index]);
  };

// <select bind:value={$locale}>
//   {#each $locales as locale}
//     <option value={locale}>{locale}</option>
//   {/each}
// </select>
</script>

<style>
button {
  position: absolute;
  padding: 5px;
  border: none;
  background-color: transparent;
  top: 0;
  left: 0;
  color: inherit;
}

span {
  font-size: 32px;
}
</style>

<button on:click={next}>
  <span class="material-icons">translate</span>
</button>
